import { GetStaticPaths, GetStaticProps } from 'next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ISbStoriesParams } from 'storyblok-js-client';

import { ParsedUrlQuery } from 'querystring';

import {
  SettingsType,
  StoryBlokResponseDataType,
  StoryblokStoryType,
  TemplatesType,
  resolveRelationString,
  storyblokClient,
} from '@lichtblick/contentful';
import { extendTemplates, ExtendedTemplates, STORY_COMPONENTS } from '@lichtblick/contentful/components/templates';
import { mapSettings, Settings } from '@lichtblick/contentful/helpers/settingsContext';
import { ParentPage } from '@lichtblick/contentful/helpers/templateMapper';

import hugoAndAppRedirectRoutes from '../.tmp/hugoAndAppRedirectRoutes.json';
import { ContentMapper } from '../helpers/contentMapper';
import { generateRobots } from '../helpers/generateRobots';
import { generateSiteMap } from '../helpers/generateSiteMap';

export type StaticProps = {
  isPreview: boolean;
  parentPages: ParentPage[];
  previewReleaseId: string | null;
  settings: Settings;
  slug: string;
  story: StoryblokStoryType<ExtendedTemplates>;
};

const isPreview = Boolean(process.env.STORYBLOK_USE_PREVIEW === 'true' && process.env.STORYBLOK_CDA_PREVIEW_TOKEN);

export const getStaticPaths: GetStaticPaths = async () => {
  const stories = (
    await storyblokClient.getAll(
      'cdn/stories',
      isPreview
        ? {
            version: 'draft',
            token: process.env.STORYBLOK_CDA_PREVIEW_TOKEN,
          }
        : { version: 'published' },
    )
  ).filter((story) => STORY_COMPONENTS.includes(story.content.component)) as StoryblokStoryType<TemplatesType>[];

  await Promise.all([generateSiteMap(stories), generateRobots(stories)]);

  const filteredPaths = [...stories.map((story) => story.full_slug), '']
    // Exclude routes that redirect to external applications from Next.js router's handling.
    .filter((path) => !hugoAndAppRedirectRoutes.includes(`/${path}`))
    .map((slug) => ({ params: { slug: slug.split('/').filter(Boolean) } }));

  // we might run into a problem here, if the params slug is not unique (e.g. because we have a page with slug "foo" and a page with at file "foo.tsx")
  // in that case we might need to add a blacklist of slugs that should not be used as dynamic paths (e.g. "404", "zuhause-checkout")
  return {
    paths: filteredPaths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<StaticProps, ParsedUrlQuery, { from_release?: string }> = async ({
  params,
  previewData,
}) => {
  const slug = (params?.slug ?? []) as string[];

  const storyblokClientParams: ISbStoriesParams = isPreview
    ? {
        version: 'draft',
        ...(previewData as any),
        token: process.env.STORYBLOK_CDA_PREVIEW_TOKEN,
        resolve_relations: resolveRelationString,
      }
    : { version: 'published', resolve_relations: resolveRelationString };

  const storyData = (
    await storyblokClient.get(`cdn/stories/${slug.length === 0 ? 'home' : slug.join('/')}`, storyblokClientParams)
  ).data as StoryBlokResponseDataType<TemplatesType>;

  const parentStoryRequests = [];

  for (let i = 1; i < slug.length; i++) {
    parentStoryRequests.push(
      storyblokClient.get(`cdn/stories/${slug.slice(0, slug.length - i).join('/')}`, storyblokClientParams),
    );
  }

  const parentPages = (
    (await Promise.all(parentStoryRequests)).map((request) => request.data.story) as StoryblokStoryType<TemplatesType>[]
  )
    .map<ParentPage>((page) => ({
      slug: page.full_slug,
      breadcrumbTitle: 'breadcrumbTitle' in page.content ? page.content.breadcrumbTitle : null,
    }))
    .reverse();

  const settings = mapSettings(
    (await storyblokClient.get(`cdn/stories/settings`, storyblokClientParams)).data.story.content as SettingsType,
  );

  return {
    props: {
      isPreview,
      story: await extendTemplates(storyData.story),
      slug: `/${storyData.story.full_slug}`,
      parentPages,
      settings,
      previewReleaseId: previewData?.['from_release'] ?? null,
    },
  };
};

export default ContentMapper;
